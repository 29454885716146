import React from 'react';
import "./css/new-design.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {BaseView} from "./views/BaseView";
//import MainPage from "./views/MainPage";
import DesignView from "./views/DesignView";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={""} element={<BaseView/>}>
          <Route index element={<DesignView />}/>
        </Route>

      </Routes>
    </BrowserRouter>
  );
}

export default App;
