import React, {useEffect, useRef, useState} from "react";
import {profileType} from "../ts/types";


const WorkProfiles = () => {
    const [profileLinks] = useState<profileType[]>([
        {
            link: "https://www.linkedin.com/in/samuel-kuteyi-4544221a4/",
            title: "LinkedIn",
            image: require('../pictures/profile/logos/linked-in.png')
        },

        {
            link: "https://www.upwork.com/freelancers/~01cda5388a3372150b",
            title: "Freelance",
            image: require('../pictures/profile/logos/upwork.png'),
            alt: "Upwork a global freelancing platform where professionals connect and collaborate remotely icon by Icons8"
        }
    ])

    let refElement = useRef<HTMLElement | null>(null);

    useEffect(() => {
        let itemObserver = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('translate-item');
                    observer.unobserve(entry.target)
                }
            })
        }, {
            threshold: .5
        })

        itemObserver.observe(refElement.current as HTMLElement)
    }, [refElement.current])
    return <div className={'work-profiles page-width'} id={'work-profiles'}>
        <header>Work profiles</header>

        <div className={'link-data'} ref={element => refElement.current = element}>
            {
                profileLinks.map((link, index) => {
                    return <a className={'link-item'} key={index} href={link.link} target={'_blank'} rel={'noreferrer'}>
                        <img src={link.image} alt={link.alt}/>
                        <div className={'link-title'}>{ link.title }</div>
                    </a>
                })
            }
        </div>
    </div>
}

export default WorkProfiles
