import React, {useEffect, useRef, useState} from "react";
import {GrClose} from "react-icons/gr";
import "../css/components/projects.scss";

interface projectItemType {
    name: string,
    description: string,
    technologies: Array<string>,
    projectUrl: string,
    imageUrls: Array<string>,
    coverImage: string,
    features: Array<string>,
    liveDemo?: string
}
const Projects = () => {
    const projects:projectItemType[] = [
        {
            name: "K.A.L Logistics app",
            description: "This application, which is based on Next.JS is a Progressive Web App that " +
                "enables users to make orders for package delivery from a " +
                "specified location to any desired destination. Users are able" +
                " to view their previous and current orders, place orders, and " +
                "create accounts. Sort through those orders, look up specific orders " +
                "in their order history, and assign ratings to agents once a delivery " +
                "has been completed. Additionally, users can make updates to the information " +
                "on their profiles. Users can also track the agent's positions in real time " +
                "while they are delivering their orders. Additionally, agents can log in and see orders that have" +
                " been placed in the vicinity of their current location. They can also " +
                "pick up and deliver orders, as well as increase or decrease the search radius " +
                "to see more or less orders, depending on their preferences.",
            technologies: [
                "HTML5", "CSS3", "NextJS", "NodeJS", "SCSS", "Typescript", "Redux"
            ],
            projectUrl: "https://kalnetwork.com",
            imageUrls: [
                require("../pictures/projects/kal/home-page.png"),
                require("../pictures/projects/kal/sign-in.png"),
                require("../pictures/projects/kal/sign-up.png"),
                require("../pictures/projects/kal/order-creation.png"),
                require("../pictures/projects/kal/order-list.png"),
                require("../pictures/projects/kal/map.png"),
                require("../pictures/projects/kal/map-marker.png"),
                require("../pictures/projects/kal/agent-dashboard.png"),
                require("../pictures/projects/kal/agent-reviews.png")
            ],
            coverImage: require("../pictures/projects/kal/home-page.png"),
            features: [
                "Account registration",
                "Log in",
                "Password reset",
                "Order placement",
                "Order pickup",
                "Order search by radius",
                "Order history viewing",
                "Real time agent position tracking",
                "Account edit",
                "Saving addresses",
                "Responsive design",
                "PWA"
            ]
        },
        {
            name: "Starling realtime messaging system",
            description: "This project was built to be a private online community where users can create accounts and" +
                "share messages, videos, pictures and even audio recordings in realtime. You get notifications for new messages and" +
                "can converse with other in app users in private spaces. It also has profile management functionality " +
                "where you can make changes to your details on the app. The entirety of the frontend is Javascript based" +
                "built with React, Typescript and the Redux state management tool. The backend was built with Laravel and other " +
                "3rd party APIs such as the Pusher API was used to ensure you can communicate with everyone else on the app no matter where" +
                "they are in realtime and have meaningful conversations.",
            technologies: ['HTML5', 'CSS3', 'SCSS', 'ReactJS', 'Redux', 'Typescript', 'Laravel', 'MySQL', 'Pusher'],
            projectUrl: "https://msstarling.com",
            imageUrls: [
                require('../pictures/projects/radar/radar-chat-item-light.gif'),
                require('../pictures/projects/radar/radar-chat-list.gif'),
                require('../pictures/projects/radar/radar-chat-system.gif'),
                require('../pictures/projects/radar/radar-chat-light.gif'),
                require('../pictures/projects/radar/radar-chats.gif'),
                require('../pictures/projects/radar/radar-contacts.gif'),
                require('../pictures/projects/radar/radar-login-dark.png'),
                require('../pictures/projects/radar/radar-login-light.png'),
                require('../pictures/projects/radar/radar-register-light.png'),
                require('../pictures/projects/radar/radar-regster-dark.png'),
                require('../pictures/projects/radar/radar-profile.gif'),
                require('../pictures/projects/radar/radar-contacts.gif')
            ],
            coverImage: require('../pictures/projects/radar/radar-chats.gif'),
            features: [
                "Account registration and authentication",
                "Email verification",
                "Contact listing",
                "Realtime chat system",
                "File sharing",
                "Voice note creation",
                "Adding/removing contacts from favorites",
                "Profile editing",
                "Realtime notification",
                "Multiple chat spaces",
                "Responsive design",
                "Light/dark mode toggle"
            ]
        },


        {
            name: "Control forte",
            description: "This React based project was built to enable users be able to create," +
                "modify and delete projects and tasks. You can create projects, assign tasks and add users to ta" +
                "sks. It has a very easy to use UI and provides good UX across mobile and desktop. You can drag tasks over from " +
                "being in the Backlog to being in progress, you can see a dashboard with a timeline of all your recent activ" +
                "ity. The entire premise of the project was to create a web application that makes it easy for you to manage all" +
                "your projects and tasks and improve your efficiency.",
            technologies: ['HTML5', 'CSS3', 'SCSS', 'ReactJS', 'Redux', 'Typescript', 'Laravel', 'MySQL'],
            projectUrl: "https://controlforte.com",
            imageUrls: [
                require('../pictures/projects/carbon/carbon-add-task.gif'),
                require('../pictures/projects/carbon/carbon-base.png'),
                require('../pictures/projects/carbon/carbon-create-new.png'),
                require('../pictures/projects/carbon/carbon-dashboard.png'),
                require('../pictures/projects/carbon/carbon-drag-task.gif'),
                require('../pictures/projects/carbon/carbon-edit.png'),
                require('../pictures/projects/carbon/carbon-login.gif'),
                require('../pictures/projects/carbon/carbon-profile.gif'),
                require('../pictures/projects/carbon/carbon-project.png'),
                require('../pictures/projects/carbon/carbon-project-list.png'),
                require('../pictures/projects/carbon/carbon-register.png'),
                require('../pictures/projects/carbon/carbon-sidebar.png')
            ],
            coverImage: require('../pictures/projects/carbon/carbon-drag-task.gif'),
            features: [
                "User login and signup",
                "Email verification",
                "Activity dashboard",
                "Project creation",
                "Project detail editing",
                "Project deletion",
                "Task creation and assignment",
                "Task update and reassignment",
                "Task deletion",
                "Drag and drop functionality",
                "Responsive design"
            ]
        },

        {
            name:  "Sales reporting system",
            description: "This is a VueJS based reporting system that allows users to see sales reports " +
                "over a selected period of time, the reports are broken down into sources and salesreps allowing " +
                "users to see reports for whatever pipeline they please. Users can also change the order of the report stages and " +
                "hide/unhide stages, filter through the stage types and see only the results they want to see. " +
                "Users can also drag and drop to change the order of tasks, see metrics such as totals and averages as well as " +
                "set KPIs for different report types. Users can also check key KPI indicators and see a breakdown of the reports.",
            technologies:  ['HTML5', 'CSS3', 'SCSS', 'VueJS', 'Javascript', 'Quasar'],
            coverImage: require('../pictures/projects/sdd/sdd-stage-drag.gif'),
            projectUrl: "https://app.turnovercrm.com",
            liveDemo: "https://reports.salesdatadashboard.com/?location_id=aNwqNMrT1bjQRDE5i58L",
            imageUrls: [
                require('../pictures/projects/sdd/filter-stages.png'),
                require('../pictures/projects/sdd/range-select.png'),
                require('../pictures/projects/sdd/reports-dashboard.png'),
                require('../pictures/projects/sdd/reports-state.png'),
                require('../pictures/projects/sdd/salesrep-report.png'),
                require('../pictures/projects/sdd/sdd-fullscreen.png'),
                require('../pictures/projects/sdd/sdd-stage-drag.gif'),
                require('../pictures/projects/sdd/sdd-stages.png'),
                require('../pictures/projects/sdd/sdd-toggle.png')
            ],
            features: [
                'Report generation',
                'Metric filtering',
                'Stage manipulation',
                'Setting KPI indicators',
                'Report breakdown',
                'Pipeline generation',
                'Stage drag & drop',
                'Responsive design'
            ]
        },


        {
            name: "Salence order & delivery system",
            description: "This application allows users to be able to create new letter and gift delivery" +
                "orders on the web application and place their order which a company sales attendant would then look into" +
                "by printing or getting their letters and gifts then deliver to the recipient(s) of the customer's choice. This" +
                "app was built to provide an easy and streamlined way for the company's clients to get their customers orders and then " +
                "work on delivering them",
            technologies:  ['HTML5', 'CSS3', 'SCSS', 'Javascript', 'jQuery', 'PHP'],
            coverImage: require('../pictures/projects/salence/salence-letter.gif'),
            projectUrl: "https://salence.us/salence-app/dashboard.php",
            imageUrls: [
                require('../pictures/projects/salence/salence-dashboard.gif'),
                require('../pictures/projects/salence/salence-gift.png'),
                require('../pictures/projects/salence/salence-letter.gif'),
                require('../pictures/projects/salence/salence-login.png'),
                require('../pictures/projects/salence/salence-recipient.png'),
                require('../pictures/projects/salence/salence-type.png')
            ],
            features: [
                "User login and signup",
                "Password recovery",
                "Letter creation",
                "Gift order creation",
                "Sending of items to multiple recipients",
                "User dashboard with order creation data",
                "Responsive design"
            ]
        },

        {
            name: "Zane medical website",
            description: "The zane medical website allows the company's customers to see important details about the company, view all the" +
                "services the company offers and book appointments with the medical centre's doctors.",
            technologies:  ['HTML5', 'CSS3', 'Javascript', 'PHP'],
            coverImage: require('../pictures/projects/zane/zane-landing.gif'),
            projectUrl: "https://zanemedical.com",
            imageUrls: [
                require('../pictures/projects/zane/zane-about.png'),
                require('../pictures/projects/zane/zane-behavorial.png'),
                require('../pictures/projects/zane/zane-clinical.png'),
                require('../pictures/projects/zane/zane-contact.png'),
                require('../pictures/projects/zane/zane-contact-2.png'),
                require('../pictures/projects/zane/zane-landing.gif')
            ],
            features: [
                "Appointment booking",
                "Service listing",
                "Open time check",
                "Responsive design",
                "Company location check"
            ]
        }
    ]

    const [projectItems] = useState<projectItemType[]>(projects);
    const [detailOpen, setDetailOpen] = useState<boolean>(false);
    const [activeProject, setActiveProject] = useState<projectItemType>(projects[0])

    return <div className={'page-width projects'} id={'projects'}>
        <header>Here's some of the stuff I've worked on:</header>
        <div className={'project-grid'}>
            {
                projectItems.map((item, index) => {
                    let descSplit = item.description.split(" ")
                    return  <div className={'grid-item'} key={index}>
                        <div className={'project-image'}>
                            <img src={item.coverImage} alt={item.name} loading={'lazy'}/>
                        </div>

                        <h3 className={'project-name'}>
                            { item.name }
                        </h3>

                        <div className={'project-description'}>
                            { descSplit.slice(0, 15).toString().replace(/,/g, " ")}
                            { descSplit.length > 15 ? '...' : '' }
                        </div>

                        <div className={'project-links'}>
                            <a className={'anchor'} href={item.projectUrl} target={'_blank'} rel={'noreferrer'}>
                                Go to live project
                            </a>
                            {
                                item.liveDemo ?
                                    <a className={'anchor'} href={item.liveDemo} target={'_blank'} rel={'noreferrer'}>
                                        See live demo
                                    </a> : null
                            }

                            <div className={'anchor'} onClick={() => { setActiveProject(item); setDetailOpen(true)}}>See full project details</div>
                        </div>
                    </div>
                })
            }

            <ProjectDetails open={detailOpen} project={activeProject as projectItemType} onClose={() => setDetailOpen(false)}/>
        </div>
    </div>
}

interface detailType {
    open: boolean,
    project: projectItemType,
    onClose: () => void
}
const ProjectDetails:React.FC<detailType> = ({ open, project, onClose }) => {
    return <div className={`detail-modal ${open ? 'open' : 'closed'}`}>
        <div className={'detail-container page-width'}>
            <div className={'close-icon'} onClick={() => onClose()}>
                <GrClose/>
            </div>
            <h3 className={'heading'}>{ project.name }</h3>

            { open ?  <ImageSlider images={project.imageUrls}/> : null}

            <div className={'detailed-content'}>
                <div className={'project-desc'}>
                    { project.description }
                </div>

                <div className={'detail-section'}>
                    <h3 className={'heading-alt'}>Technologies</h3>

                    <div className={'tech-list'}>
                        {
                            project.technologies.map((item, index) => {
                                return <div className={'tech-item'} key={index}>{item}</div>
                            })
                        }
                    </div>
                </div>

                <div className={'project-features'}>
                    <div className={'heading-alt'}>Features</div>

                    <ul>
                        {
                            project.features.map((feature, index) => {
                                return <li key={index}>
                                    { feature }
                                </li>
                            })
                        }
                    </ul>
                </div>

                <div className={'link-section'}>
                    { project.liveDemo ?
                        <a target={'_blank'} rel={'noreferrer'} href={project.liveDemo}>See live demo</a> : null}
                    <a target={'_blank'} rel={'noreferrer'} href={project.projectUrl}>Go to live project</a>
                </div>
            </div>
        </div>
    </div>
}

interface sliderTypes {
    images: Array<string>
}
const ImageSlider:React.FC<sliderTypes> = ({ images }) => {
    const sliderContainer = useRef<HTMLElement | null>(null);
    const slider = useRef<HTMLElement | null>(null);
    const slidePosition = useRef<number>(0);
    const slideTimeout = useRef<number>(0);


    function animateSlide(direction: 'forward' | 'reverse') {
        let sliderMain = slider.current as HTMLElement, container = sliderContainer.current as HTMLElement;
        const transformPos = container.clientWidth * slidePosition.current;
        sliderMain.style.transition = "ease .8s";

      let margin =  parseInt(getComputedStyle(sliderMain.children[0]).marginRight);

        sliderMain.style.transform = `translateX(-${transformPos}px)`;

        if ( direction === 'forward') {
            slidePosition.current += 1;
        } else {
            slidePosition.current -= 1;
        }

        if (slidePosition.current != 1) {
            window.clearTimeout(slideTimeout.current)
        }
        automaticSlide();
    }

    function automaticSlide() {
        let container = sliderContainer.current as HTMLElement,
            sliderMain = slider.current as HTMLElement;

      slideTimeout.current =  window.setTimeout(() => {
            let itemWidth = sliderMain.children[0].clientWidth, totalItems = sliderMain.children.length,
                margin =  parseInt(getComputedStyle(sliderMain.children[0]).marginRight);

            margin = isNaN(margin) ? 0 : margin;
            const maxWidth = (itemWidth * totalItems) + (margin * (totalItems));
            const transformPos = container.clientWidth * slidePosition.current;
            if (transformPos < (maxWidth - container.clientWidth)) {
              //  console.log({ maxWidth, transformPos })
               animateSlide('forward')
            } else {
                let items = Math.round(container.clientWidth / itemWidth);
                items = isNaN(items) ? 0 : items;
                let cloneItems = new Array(items).fill('')

                cloneItems.forEach((item, index) => {
                    let cloneItem = sliderMain.children[index].cloneNode(true);
                    sliderMain.appendChild(cloneItem)
                });
                slidePosition.current += 1
                console.log({ transformPos })

                sliderMain.style.transition = "ease .8s";

                sliderMain.style.transform = `translateX(-${transformPos}px)`;
                setTimeout(() => {
                    sliderMain.style.transition = "none";
                    sliderMain.style.transform = `translateX(0)`;
                    slidePosition.current = 0;

                    let itemLength = sliderMain.children.length-1
                    cloneItems.forEach((item, index) => {
                        sliderMain.children[itemLength-index].remove();
                    })
                    window.clearTimeout(slideTimeout.current)
                    automaticSlide();
                }, 800)
            }
        }, 3000)
    }

    useEffect(() => {
        let container = sliderContainer.current as HTMLElement,
            sliderMain = slider.current as HTMLElement;

        if (container != null && sliderMain != null) {
            automaticSlide()
        }


    }, [sliderContainer.current, slider.current])
    return <div className={'project-images'} ref={element => sliderContainer.current = element}>
       <div className={'image-slider'} ref={element => slider.current = element}>
           {
               images.map((image, index) => {
                   return <div className={'image-item'} key={index}>
                        <img src={image} alt={''} loading={'lazy'}/>
                   </div>
               })
           }

       </div>
    </div>


}

export default Projects
