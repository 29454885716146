import React, {useEffect, useRef, useState} from "react";
import { profileType } from "../ts/types";
import "../css/components/skills.scss";


const Skills = () => {
    const [skillList] = useState<profileType[]>([
        {
           image: require('../pictures/skills/html5.png'),
            title: "HTML5"
        },
        {
            image: require('../pictures/skills/css3.png'),
            title: "CSS3"
        },
        {
            image: require('../pictures/skills/sass.png'),
            title: "SCSS",
        },
        {
            image: require('../pictures/skills/tailwindcss-48.png'),
            title: "TailwindCSS"
        },
        {
            image: require('../pictures/skills/javascript.png'),
            title: "Javascript"
        },
        {
            image: require('../pictures/skills/react.png'),
            title: "ReactJS"
        },
        {
            image: require('../pictures/skills/nextjs-icon.png'),
            title: "NextJS"
        },
        {
            image: require('../pictures/skills/typescript.png'),
            title: "Typescript"
        },
        {
            image: require('../pictures/skills/redux.png'),
            title: "Redux"
        },
        {
            image: require('../pictures/skills/react-native.png'),
            title: "React Native"
        },
        {
            image: require('../pictures/skills/vue.png'),
            title: "VueJS"
        },
        {
            image: require('../pictures/skills/nuxtjs-icon.png'),
            title: "NuxtJS"
        },
        {
            image: require('../pictures/skills/php.png'),
            title: "PHP"
        },
        {
            image: require('../pictures/skills/laravel.png'),
            title: "Laravel"
        },
        {
            image: require('../pictures/skills/mysql.png'),
            title: "MySQL"
        },
        {
            image: require('../pictures/skills/shopify.png'),
            title: "Shopify"
        }

    ])


    let containerElement = useRef<HTMLElement | null>(null);
    let skillItems = useRef<HTMLElement[]>([]);
    let maxAnimation = useRef<number>(0);
    useEffect(() => {

        function animateItem(index = 0) {
            if (skillItems.current != null) {

                if (index === 0) {
                    maxAnimation.current = skillItems.current.length;
                }

                let item = skillItems.current[index];
               if (item) {
                   item.classList.add('fade-item');

                   if (index <  maxAnimation.current) {
                      setTimeout(() => {
                          animateItem(index + 1)
                      }, 500)
                   }
               }
            }
        }

        let intersectionObserver = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    //console.log({ entry: entry.target, items: skillItems.current })
                    animateItem()
                    observer.unobserve(entry.target)
                }
            })
        }, {
            threshold: .5
        })

        intersectionObserver.observe(containerElement.current as HTMLElement)
    }, [maxAnimation.current])

    return <div className={'skills page-width'} id={'tech-stacks'}>
         <header>Skills</header>

        <div className={'link-data'} ref={element => containerElement.current = element}>
            {
                skillList.map((skill, index) => {
                    return <div className={`link-item`} key={index} ref={element => skillItems.current[index] = element as HTMLElement}>
                            <img src={skill.image} alt={skill.title} className={`${skill.title.toLowerCase().replace(/\s/g, "-")}`}/>
                            <div className={'link-title'}>{ skill.title }</div>
                    </div>
                })
            }
        </div>
    </div>
}

export default Skills;
