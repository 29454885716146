import React, {useState} from "react";
import '../css/custom-design.scss';
/*import TextOutline from "../components/TextOutline";
import TextWriter from "../components/TextWriter";
import Banner from "../components/Banner";*/
import BannerSection from "../components/BannerSection";
import CareerPath from "../components/CareerPath";
import {BiCopyright} from "react-icons/bi";
import Projects from "../components/Projects";
import Skills from "../components/Skills";
import WorkProfiles from "../components/WorkProfiles";
import ContactSection from "../components/ContactSection";
const DesignView = () => {

    return <div className={'design-container'}>
            <BannerSection/>
            <CareerPath/>
            <Projects/>
            <WorkProfiles/>
            <Skills/>
            <ContactSection/>
        <div className={'footer-section'}>
            <BiCopyright className={'icon'}/><span>{ new Date().getFullYear() }</span>
        </div>
    </div>
}

export default DesignView
