import React, {FormEvent, useEffect, useState} from "react";
import axios from "axios";
import "../css/components/contact-section.scss";

interface fieldType{
    name: string,
    type: string,
    label?: string,
    value: string,
    placeholder: string,
    hasError: boolean,
    textArea: boolean
}
const ContactSection = () => {
    let fieldItems:fieldType[] = [
        {
            name: "name",
            type: "text",
            placeholder: "Your name",
            value: "",
            hasError: false,
            textArea: false
        },

        {
            name: "message",
            type: "text",
            placeholder: "Your message",
            value: "",
            hasError: false,
            textArea: true
        }
    ]
    interface feedbackType {
        type: 'error' | 'success',
        text: string,
        open: boolean
    }
    const [fields, updateFields] = useState<fieldType[]>(fieldItems);
    const [sendingMessage, setSendingMessage] = useState<boolean>(false);
    const [requestFeedback, setRequestFeedback] = useState<feedbackType>({
        type: 'success',
        text: '',
        open: false
    })


    const setValue = (index:number, value: string)  : void => {
        let fieldValues = [...fields];
        fieldValues[index].value = value
        updateFields([...fieldValues])
    }

    const handleSubmit = (event: FormEvent) : void => {
        event.preventDefault();

        interface requestInterface {
            name: string,
            message: string
        }
        let requestObject:requestInterface = {
            name: "",
            message: ""
        };

        fields.forEach(field => {
            requestObject[field.name as keyof requestInterface] = field.value
        })

        let baseUrl = 'https://api.msstarling.com/api/'

        setSendingMessage(true)

        axios.post(`${baseUrl}mail/me`, requestObject).then(response => {
            console.log({ response })


            setSendingMessage(false)
            setRequestFeedback({ ...requestFeedback, ...{
                type: 'success',
                text: 'Got it! Will reach back out ASAP',
                open: true
            }})


           updateFields(fields.map(field => { field.value = ''; return field}))
        }).catch(error => {
            console.log({ error })
            setSendingMessage(false)
            setRequestFeedback({ ...requestFeedback, ...{
                type: 'error',
                text: 'Sorry, looks like there was an error sending your message',
                open: true
            }})
        })
    }

    useEffect(() => {
        if (requestFeedback.open) {
            setTimeout(() => {
                setRequestFeedback({...requestFeedback, open: false})
            }, 3000)
        }
    }, [requestFeedback])
    return <div className={'page-width contact-section'} id={'contact'}>
            <div className={'section-item desc-section'}>
                <h3>Let's get in touch!</h3>
                <p>
                    Looking to hire or collaborate on a project? Fill out the form and I'll be getting back to you ASAP.
                    Look forward to hearing from you.
                </p>
            </div>

        <div className={'section-item form-section'}>
            <form onSubmit={event => handleSubmit(event)}>

                {
                    fields.map((field, index) => {
                        return <div className={'input-control'} key={index}>
                            {
                                !field.textArea ? <input
                                    type={field.type}
                                    name={field.name}
                                    placeholder={field.placeholder}
                                    value={field.value}
                                    onChange={event => setValue(index, event.target.value)}

                               /> : <textarea
                                    name={field.name}
                                    placeholder={field.placeholder}
                                    value={field.value}
                                    onChange={event => setValue(index, event.target.value)}
                                />

                                }
                        </div>
                    })
                }

                {
                    requestFeedback.open ? <div className={`request-feedback ${requestFeedback.type}`}>
                        { requestFeedback.text }
                    </div> : null
                }


                <button className={'button'} disabled={fields.filter(field => field.value === '').length > 0}>
                    { sendingMessage ? 'Sending message...': 'Send message'}
                </button>
            </form>
        </div>
    </div>
}

export default ContactSection;
