import React, {useEffect, useRef, useState} from "react";
import "../css/components/navigation.scss";
import {Link} from "react-router-dom";
import {GiHamburgerMenu} from "react-icons/gi";
import { GrClose } from "react-icons/gr";

interface linkType {
    title: string,
    link: string,
}
interface navProps {
    open: boolean,
    onClose: () => void,
    onOpen: () => void,
    onNavigate: (section: string) => void
}
const Navigation:React.FC<navProps> = ({ open, onClose, onOpen, onNavigate }) => {
    const linkItems:linkType[] = [
        {
            title: "Projects",
            link: "#projects"
        },

        {
            title: "Career journey",
            link: "#career-journey"
        },

        {
            title: "Work profiles",
            link: "#work-profiles"
        },

        {
            title: "Skills",
            link: "#tech-stacks"
        },

        {
            title: "Contact me",
            link: "#contact"
        }
    ]
    const [navItems] = useState<linkType[]>(linkItems);

    let navBar = useRef<HTMLElement | null>(null);

    useEffect(() => {
        window.addEventListener("scroll", function () {
            let scrollTop = this.scrollY
            let navigation = navBar.current as HTMLElement;
            if (navigation != null) {
                if (scrollTop > 0 && !navigation.classList.contains("offset")) {
                    navigation.classList.add('offset');
                }

                if (scrollTop === 0 && navigation.classList.contains('offset')) {
                    navigation.classList.remove('offset')
                }
            }
        })
    }, [])

    return <>
        <nav className={'main-navigation'} ref={element => navBar.current = element}>
            <ul className={'navigation-list'}>
                <NavList navItems={navItems} onSelect={linkId => { onNavigate(linkId); onClose()}}/>
            </ul>
        </nav>

        <div className={'hamburger-menu'} onClick={() => onOpen()}>
            <GiHamburgerMenu/>
        </div>

        <div className={`mobile-navigation ${!open ? "closed" : ''}`}>
            <div className={'nav-content'}>
                <div className={'close-icon'} onClick={() => onClose()}>
                    <GrClose/>
                </div>
                <ul>
                    <NavList navItems={navItems} onSelect={linkId => {
                        onClose()
                        onNavigate(linkId)
                    }}/>
                </ul>
            </div>
        </div>
    </>
}
interface listProps {
    navItems: linkType[],
    onSelect: (linkId: string) => void
}
const NavList:React.FC<listProps> = ({ navItems, onSelect }) => {
    return <>
        {
            navItems.map((item, index) => {
                return <li key={index}>
                    <Link to={item.link} onClick={() => {
                        onSelect(item.link.slice(1))
                    }}>{item.title}<span className={'bottom-border'}/></Link>
                </li>
            })
        }
    </>
}

export default Navigation
