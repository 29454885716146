import React, {useEffect, useState} from "react";
import "../css/components/banner-section.scss";
import TextWriter from "./TextWriter";
import TextOutline from "./TextOutline";
import {scrollToSection} from "../helpers/functions";
const BannerSection:React.FC = () => {
     const [ visible, setVisible ] = useState<boolean>(true);
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [currentState, updateCurrentState] = useState<'outline' | 'writer'>('outline');
    const [animationComplete, setAnimationComplete] = useState<boolean>(false);
    function detectComplete() {
        console.log('Clicked')
       // setVisible(false)
    }

    const words = [
        { text: 'Hello,', color: '#FFFFFF'},
        { text: 'I am', color: '#FFFFFF'},
    ]

    useEffect(() => {
        updateCurrentState('outline');
        setAnimationComplete(false)
        //eslint-disable-next-line
    }, [])

    function handleComplete(index: number):void {
        if (index + 1 >= words.length) {
            updateCurrentState('writer')
            return
        }

        setActiveIndex(index+1)
    }

    function handleAnimationComplete():void {
        setTimeout(function () {
            setAnimationComplete(true)
        }, 150)
    }
    return <div className={'banner-section'}>
        <div className={'banner-content'}>
           <div className={'animated-content'}>
               <div className={`display-name ${animationComplete ? 'in-view' : ''}`}>Samuel Kuteyi</div>
               {
                   currentState === 'outline' ?  words.map((word, index) => {
                       return <div key={index}>
                           {
                               activeIndex === index ? <TextOutline word={word.text} color={word.color} complete={() => handleComplete(index)} /> : <></>
                           }
                       </div>
                   }) : <TextWriter onComplete={() => handleAnimationComplete()} />
               }
               <div className={`display-buttons ${animationComplete ? 'in-view' : ''}`}>
                   <button onClick={() => scrollToSection("career-journey")} className={'button'}>Get to know me</button>
               </div>
           </div>
        </div>

       {/* <TextOutline word={}/>*/}
    </div>
}

export default BannerSection
