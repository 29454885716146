import React, {useEffect, useRef, useState} from "react";
import "../css/text-outline.scss";
interface propTypes {
    word: string,
    complete?: () => void,
    color?: string
}
const TextOutline = ({ word, complete, color }:propTypes) => {
    const elementData = useRef<HTMLElement | null>(null);

    const [animate, setAnimate] = useState<boolean>(false)

    useEffect(() => {
        setTimeout(function () {
            setAnimate(true)
            setTimeout(function () {
              if (complete) {
                  complete()
              }
            }, 1000)
        }, 100)




    }, [])


    useEffect(() => {
        if (elementData.current) {
            elementData.current?.style.setProperty('--outline-width', elementData.current?.clientWidth + 1 + 'px');

            if (color) {
                elementData.current?.style.setProperty('--text-color', color)
            }

        }
    }, [elementData.current, word])


    function getElement(element: HTMLElement) {
        if (element) {

            elementData.current = element
        }
    }
    return <div ref={element => getElement(element as HTMLElement)}
                className={'text-outline'}>
            <span className={'outline-text bg-text'}>{ word }</span>
            <div className={'outline-text main-text'}>
                <div className={`span-text ${ animate ? 'animate': ''}`}>
                    <span>{ word }</span>
                </div>
            </div>
    </div>
}

export default TextOutline
