import React from "react";
import {SiUpwork} from "react-icons/si";
import "../css/components/career-path.scss";

const CareerPath = () => {
    return <section className={'career-path-section'}>
        <div className={'page-width career-path'} id={'career-journey'}>
            <div className={'content-section'}>
                <header>My Career Path</header>

                <p>
                    I started out in 2018 learning the basics of frontend development
                    using frontend technologies such as HTML, CSS and VanillaJS. I then learnt jQuery and started building out small
                    frontend applications before learning backend development with PHP and started building fullstack applications.
                    I then started working at <a href={'https://www.linkedin.com/company/robingat-consulting/'} target={'_blank'} rel={'noreferrer'}> Robingat consulting</a>
                    building out websites for the company's clients as a full stack developer and gaining relevant experience.
                </p>

                <p>In the process of working and growing, I learnt new and better ways to approach projects, using frameworks and libraries to
                    develop more robust and organized web applications with great architecture. I learnt ReactJS, VueJS and PHP's Laravel framework
                    and joined <a href={'https://upwork.com'} target={'_blank'} className={'upwork'} rel={'noreferrer'}><SiUpwork className={'icon'}/> Upwork</a> as a freelancer,
                    where I gained a whole lot more experience working with different tech companies and clients from all around the world and became top rated in
                    just around a year of becoming a freelancer with stellar reviews and a <b>100% job success</b> rate. I built some cool projects
                    along the way, enjoying the journey and learnt new technologies like Typescript, Redux and React Native. I am still a top rated developer
                    till this day.
                </p>

                <p>
                    I joined <a href={'https://coalitiontechnologies.com'} target={'_blank'} rel={'noreferrer'}>Coalition technologies</a> full time in 2021 as a frontend developer where we
                    built we Shopify e-commerce stores for clients over all the USA and all around the world. I then started working full-time for <a href={'https://turnovercrm.com'} target={'_blank'} rel={'noreferrer'}>Turnover CRM</a>,
                    an automotive company based in Canada that helps connects customers with quality vehicles. I help build and maintain multiple internal systems within the company
                    that help them with customer relations and connecting customer data to different lenders in the country.
                </p>
            </div>

            <div className={'image-section'}>
                <div className={'image-shape image-rectangle'}></div>
                <div className={'image-shape image-triangle'}>
                    <div className={'tri-shape'}></div>
                </div>
                <div className={'main-image'}>
                   <div className={'image-cover'}>
                       <img src={require('../pictures/profile/profile-picture-alt.jpg')} alt={'cover photo'}/>
                       <div className={'image-overlay'}></div>
                   </div>
                </div>
            </div>
        </div>
    </section>
}

export default CareerPath
