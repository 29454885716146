import React, {useEffect, useState} from "react";
import Navigation from "../components/Navigation";
import { Outlet } from "react-router-dom";
import {scrollToSection} from "../helpers/functions";

export const BaseView = () => {
    const [open, setOpen] = useState<boolean>(false);




    return <>
        <Navigation
            open={open}
            onNavigate={section => scrollToSection(section)}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
        />
        <>
            <Outlet/>
        </>
    </>
}
