export const scrollToSection = (section: string) => {
    let element = document.getElementById(`${section}`)

    if (element) {
        window.scroll({
            top: element.offsetTop,
            left: 0,
            behavior: "smooth"
        })
    }
}
